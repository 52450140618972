/* ALERT MODAL "COOKIE POLICY" FOR EUROPARL ELECTIOn 2024 */
/* Component "COOKIES ALERT" */
/* Copyright : European Parliament */

/* ========================================================================================================================================================================== */
/* == GENRIC STYLES ============================================================================================================================== */
/* ========================================================================================================================================================================== */

/*  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
/* STYLE GENERIQUES : Conteneurs principaux */

$font-size: 1rem;
$line-height: 1.4rem;

body {
  #cookie-policy{
    *{
      box-sizing: border-box;
    }
    &.epjs_cookiepolicy {
      position: fixed;
      display: block;
      z-index: 5000;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      margin: 0;
      font-family: EPGammaRegular,Helvetica,arial,sans-serif;
      &.epjs_displayed .cookie-consent-popup-container {
          transform: translate(0, 0);
          transition: transform 0.5s ease-out 0s;
          & > div {
            &:focus{
              outline: none;
            }
          }
      }
      .cookie-consent-popup-container {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 40px 0;
        transform: translate(0, 110%);
        transition: transform 0.5s ease-out 0s;
        color: #1E1E1F;
        background-color: #FCFCFC;
        box-shadow: 6px 0 12px #00000029;
        .cookie-consent-popup-wrapper{
          display: flex;
          flex-direction: column;
          max-width: 1176px;
          margin: 0 auto;
          padding: 0 20px;
          .epjs_text {
            display: block;
            margin: 0;
            color: #1E1E1F;
            font-size: $font-size;
            line-height: $line-height;
            -webkit-font-smoothing: auto;
            a, p{
              font-size: $font-size;
              line-height: $line-height;
            }
            p{
              margin-top: 0;
              margin-bottom: 20px;
              color: #1E1E1F;
              &:first-of-type{
                margin-bottom: 0;
              }
            }
            a{
              &.cc-link-default{
                color: #024EA2 !important;
                display: inline-block;
                &:hover {
                  color: #003a79 !important;
                }
                &:focus {
                  outline: solid 2px #1E6CFF;
                  outline-offset: 4px;
                }
              }
            }
          }
          .epjs_buttons {
            margin: 25px 0 0;
            padding: 0;
            text-align: center;
            color: inherit;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            flex-wrap: wrap;
            a, a:link, a:visited, button{
              display: inline-block;
              width: auto;
              margin: 0 0 15px;
              color: #fff;
              padding: 14px 25px;
              background: #024EA2;
              border-radius: 6px;
              outline: none;
              cursor: pointer;
              font-family: EPGammaRegular,Helvetica,arial,sans-serif;
              font-size: $font-size;
              line-height: $line-height;
              font-weight: bold;
              text-align: center;
              vertical-align: middle;
              transition: background-color .3s ease-in-out;
              &:last-of-type{
                margin: 0;
              }
            }
            a:hover, a:focus, button:hover, button:focus{
              background: #003a79;
              color: #fff;
            }
            a:focus, button:focus{
              outline: solid 2px #1E6CFF;
              outline-offset: 4px;
            }
          }
        }
        @media screen and (min-width: 768px) {
          padding: 40px 20px;
          .cookie-consent-popup-wrapper{
            flex-direction: row;
            .epjs_text{
              width: 60%;
              font-size: 1.125rem;
              line-height: 1.75rem;
              margin-right: 20px;
              a, p{
                font-size: 1.125rem;
                line-height: 1.75rem;
              }
            }
            .epjs_buttons{
              width: 40%;
              margin: 0;
              align-items: flex-end;
              a, a:link, a:visited, button{
                font-size: 1.125rem;
                line-height: 1.75rem;
                padding: 16px 40px;
                margin-bottom: 25px;
              }
            }
          }
        }
      }
      &:focus{
        outline: none;
      }
    }
  }
}
